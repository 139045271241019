import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import Home from '../views/Home.vue'
const SalarieRegister = () => import('../views/SalarieRegister')
const Login = () => import('../views/Login')
const Logout = () => import('../views/Logout')
const SalarieDashboard = () => import('../views/SalarieDashboard')
const ManagerSalarieDashboard = () => import('../views/ManagerSalarieDashboard')
const ConseillerSalariesDashboard = () => import('../views/ConseillerSalariesDashboard')
const ConseillerSalarieProfile = () => import('../views/ConseillerSalarieProfile')

const PasswordReset = () => import('../views/settings/PasswordReset')
const PasswordResetConfirm = () => import('../views/settings/PasswordResetConfirm')
const SalarieProfileEdition = () => import('../views/settings/SalarieProfileEdition')
const SalarieModifyPassword = () => import('../views/settings/SalarieModifyPassword')

const Contact = () => import('../views/Contact')
const Help = () => import('../views/Help')

const ConfidentialityPolitique = () => import('../views/information/ConfidentialityPolitique')

const MentionsLegales = () => import('../views/information/MentionsLegales')

const ConditionsUtilisation = () => import('../views/information/ConditionsUtilisation')

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const isUserGroup = (group_name) => {
  var userGroups = store.getters.getUserGroups
  var userGroupsArray = []
  var is_contained = false
  if (userGroups) {
    try {
      var userGroupsJson = JSON.parse(userGroups)
      for (var i = 0; i < userGroupsJson.length; i++) {
        userGroupsArray.push(
          userGroupsJson[i]['name']
        )
      }
    }
    catch(error) {
      return is_contained
    }
  }

  for (var j = 0; j < group_name.length; j++) {
    if (userGroupsArray.indexOf(group_name[j]) > -1) {
      is_contained = true
    }
  }
  return is_contained
}

const isConnectedSalarie = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['SALARIE'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}

const isConnectedConseiller = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['CONSEILLER'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}

const isConnectedManager = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['MANAGER'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/login')
  }
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/create-account',
    name: 'SalarieRegister',
    component: SalarieRegister,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/salarie-dashboard',
    name: 'SalarieDashboard',
    component: SalarieDashboard,
    beforeEnter: isConnectedSalarie
  },

  {
    path: '/manager-dashboard/salaries',
    name: 'ManagerSalarieDashboard',
    component: ManagerSalarieDashboard,
    beforeEnter: isConnectedManager
  },
  {
    path: '/conseiller-dashboard/salaries',
    name: 'ConseillerSalariesDashboard',
    component: ConseillerSalariesDashboard,
    beforeEnter: isConnectedConseiller
  },
  {
    path: '/conseiller-dashboard/salarie/:id',
    name: 'ConseillerSalarieProfile',
    component: ConseillerSalarieProfile,
    beforeEnter: isConnectedConseiller
  },
  {
    path: '/settings/reset-password',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/settings/reset-password-confirm',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/settings/profile-edition',
    name: 'SalarieProfileEdition',
    component: SalarieProfileEdition,
    beforeEnter: isConnectedSalarie
  },
  {
    path: '/settings/modify-password',
    name: 'SalarieModifyPassword',
    component: SalarieModifyPassword,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
  },
  {
    path: '/politique-de-confidentialite',
    name: 'ConfidentialityPolitiqueHelp',
    component: ConfidentialityPolitique,
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: MentionsLegales,
  },
  {
    path: '/conditions-utilisations',
    name: 'ConditionsUtilisation',
    component: ConditionsUtilisation,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
