import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
//import { BFormDatepicker } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
/*import VueMoment from 'vue-moment'
const moment = require('moment')
require('moment/locale/fr')*/
import VueDayjs from 'vue-dayjs-plugin'
import Vuelidate from 'vuelidate'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/info-circle'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/dot-circle'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/check-circle'
import Icon from 'vue-awesome/components/Icon'
import browserDetect from "vue-browser-detect-plugin";

import VueGtag from "vue-gtag";

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
  dsn: 'https://f862af66211141618fb83d57a2a08982@o373339.ingest.sentry.io/5268965',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
  environment: process.env.NODE_ENV
});

Vue.use(VueGtag, {
  config: { id: "UA-161958571-4" }
}, router);


Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(BootstrapVue)
//Vue.component('b-form-datepicker', BFormDatepicker)
//Vue.use(VueMoment, {moment})
Vue.use(VueDayjs)
Vue.use(Vuelidate)
Vue.use(browserDetect)
Vue.component('v-icon', Icon)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
