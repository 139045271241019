import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_LOGOUT } from '../actions/user'
import { AUTH_LOGOUT } from '../actions/auth'
import { AuthAPIService }  from '@/api/APIAuth'

const apiAuth = new AuthAPIService()

const state = {
  status: '',
  userGroups: localStorage.getItem('user-group') || '',
  userFirstName: localStorage.getItem('user-first_name') || '',
  userLastName: localStorage.getItem('user-last_name') || '',
}

const getters = {
  getUserGroups: state => state.userGroups,
  getUserFirstName: state => state.userFirstName,
  getUserLastName: state => state.userLastName,
}

const actions = {
  [USER_REQUEST]: ({commit, dispatch}, token) => {
    commit(USER_REQUEST)
    apiAuth.getConnectedUser(token)
      .then(resp => {
        commit(USER_SUCCESS, resp.data)
      })
      .catch(() => {
        commit(USER_ERROR)
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT)
      })
  },
  [USER_LOGOUT]: ({commit}) => {
    commit(USER_LOGOUT)
  }
}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.userGroups = JSON.stringify(resp.groups)
    localStorage.setItem('user-group', JSON.stringify(resp.groups))
    state.userFirstName = resp.first_name
    localStorage.setItem('user-first_name', resp.first_name)
    state.userLastName = resp.last_name
    localStorage.setItem('user-last_name', resp.last_name)
    //Vue.set(state, 'profile', resp)
  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
  },
  [USER_LOGOUT]: (state) => {
    state.userGroups = ''
    localStorage.removeItem('user-group')
    state.userFirstName = ''
    localStorage.removeItem('user-first_name')
    state.userLastName = ''
    localStorage.removeItem('user-last_name')
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
