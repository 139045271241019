<template>
  <div class="home">
    <section id='home-banner' :style="{ backgroundImage: 'url(' + require('@/static/images/banniere_audiens_blur.png') + ')' }">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="banner-title">
                Bienvenue sur votre <br>plateforme accompagnement retraite
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section id='home-product' class="move-bottom-and-appear-animation">
      <div class="container-fluid" >

        <!-- Non connecté -->
        <div v-if="!isAuthenticated" class="row">
          <div class="col-sm-6 col-12 text-center mb-4" style="display: table-cell;">
            <div class="picto-wrapper" @click='clickOnCreateAccountButton'>
              <div class="picto">
                <img class="picto-img" :src="require('@/static/images/clipboard.svg')" background-color="black" alt="Créer un compte"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3 class="product-title"> Créer votre compte </h3>
                <em>pour demander la réalisation de votre bilan retraite individuel </em>
              </div>
            </div>
            <b-button class="mt-3" @click='clickOnCreateAccountButton' variant="outline-primary" style="white-space: normal;">
              Créer mon compte
            </b-button>
          </div>
          <div class="col-sm-6 col-12 text-center mb-4">
            <div class="picto-wrapper" @click='clickOnDashboardButton'>
              <div class="picto">
                <img class="picto-img" :src="require('@/static/images/dashboard.svg')" alt="Accéder à votre tableau de bord"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3 class="product-title"> Accéder à votre tableau de bord </h3>
                <em> pour télécharger vos documents et récupérer votre bilan retraite individuel</em>
              </div>
            </div>
            <b-button class="mt-3" @click='clickOnDashboardButton' variant="outline-primary" style="white-space: normal;">
              Accéder à mon tableau de bord
            </b-button>
          </div>
        </div>

        <!-- Connecté -->
        <div v-if="isAuthenticated" class="row">
          <div class="col-12 text-center mb-4">
            <div class="picto-wrapper" @click='clickOnDashboardButton'>
              <div class="picto">
                <img class="picto-img" :src="require('@/static/images/dashboard.svg')" alt="Votre produit d'assurance prévoyance"/>
              </div>
            </div>
            <div class="product-header">
              <h3 class="product-title"> Accéder à votre tableau de bord </h3>
              <small> pour télécharger vos documents et récupérer votre bilan retraite individuel</small>
            </div>
            <b-button @click='clickOnDashboardButton' variant="outline-primary" style="white-space: normal;">
              Accéder à mon tableau de bord
            </b-button>
          </div>
        </div>
      </div>
    </section>

    <section id='home-explanation' style="background-color: #F4F8FF;">
      <div class="container pt-2" >
        <helpComponent />
      </div>
    </section>

    <footer style="background-color: white;">
      <cookie-law theme="blood-orange" buttonText="Accepter">
        <div slot="message">
          Le respect de votre vie privée est notre priorité - En poursuivant la navigation sur ce site,
          vous acceptez l'utilisation de cookies utilisés dans la seule fin d'améliorer votre expérience et
          de réaliser des statistiques d'audience anonymisés.
        </div>
      </cookie-law>
			<div class="container">
				<div class="row pt-3">
					<div class="col-12 col-sm-6 text-center">
						<h2 class="help-title"> Liens vers </h2>
						<ul class="text-center footer_list">
							<li>
								<a href="https://www.audiens.org/accueil.html">Audiens</a>
							</li>

						</ul>
					</div>
					<div class="col-12 col-sm-6 mt-3 mt-sm-0 text-center">
						<h2 class="help-title">A propos</h2>
						<ul class="text-center footer_list">
              <li>
								<router-link to="/mentions-legales">Mentions légales</router-link>
							</li>
              <li>
								<router-link to="/conditions-utilisations">Conditions générales d'utilisation</router-link>
							</li>
              <li>
								<router-link to="/politique-de-confidentialite">Politique de confidentialité</router-link>
							</li>
							<li>
								<router-link to="/contact">Contactez-nous</router-link>
							</li>
						</ul>
					</div>
				</div>
					<hr>
        <div class="text-center">
					<p >Votre plateforme Accompagnement retraite</p>
        </div>
			</div>
		</footer>

  </div>
</template>

<script>
// @ is an alias to /src
const helpComponent = () => import('@/components/HelpComponent')
const CookieLaw = () => import('vue-cookie-law')


export default {
  name: 'Home',
  components: {
    helpComponent,
    CookieLaw
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
  },
  methods: {
    clickOnCreateAccountButton () {
      this.$router.push('/create-account')
    },
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/login')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    },

    clickOnDashboardButton () {
      if (this.userGroupContains(['SALARIE'])) {
        this.$router.push('/salarie-dashboard')
      }
      else if (this.userGroupContains(['MANAGER'])) {
        this.$router.push('/manager-dashboard/salaries')
      }
      else if (this.userGroupContains(['CONSEILLER'])) {
        this.$router.push('/conseiller-dashboard/salaries')
      }
      else {
        this.$router.push('/login')
      }
    },

  }

}
</script>

<style>
.img {
  margin-right: -15px;
  margin-left: -15px;
}

#home-banner {
  background-size: cover;
  background-position:center;
  text-align: center;
  height: 450px;
  padding-top: 15px;
  /* Add the blur effect */
}


.banner-title {
  /*background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.4);
  border: 1px solid #f1f1f1;*/
  color: white;
  font-size: 26px;
  font-weight: bold;
  padding: 30px;
  margin-top: 50px;

}

.banner-slogan {
  color: white;
  font-size: 20px;
}

#home-product {
  padding-top: 20px;
  background-color: white;
}

.picto-wrapper {
  position: relative;
  background: black;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 1px;
  cursor: pointer;
}

.picto {
  position: relative;
  background: #F2F2F2;
  padding-bottom: 100%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.picto:hover {
  background-color: #F3F6FF;
}

.picto-img {
  margin-top: 23px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.product-title {
  margin-bottom: 0;
}


.move-bottom-and-appear-animation {
  animation-name: move-bottom, appear;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.move-right-animation {
  animation-name: move-right;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}


@keyframes move-bottom {
  from{transform: translateY(100px);}
  to{transform: translateY(0px);}
}

@keyframes move-right {
  from{transform: translateX(50px);}
  to{transform: translateX(0px);}
}

@keyframes appear {
  from{opacity: 0;}
  to{opacity: 1;}
}

@media (min-width: 480px) {
  .banner-title {
    font-size: 30px;
  }
  .banner-slogan {
    font-size: 24px;
  }
  #home-product {
    padding-top: 0;
  }
  #home-product .container-fluid {
    position: relative;
    top: -70px
  }
  #home-explanation {
    position: relative;
    top: -70px;
  }
  #bandeau-banner {
    font-size: 16px;
  }

}

@media (min-width: 580px) {
  .product-body {
    height: 160px;
  }
}



@media (min-width: 768px) {
  #home-banner {
    height: 580px !important;
  }
  .banner-title {
    font-size: 38px !important;
  }
  .banner-slogan {
    font-size: 26px !important;
  }
  .picto-wrapper {
    width: 200px;
    height: 200px;
  }
  .picto-img {
    margin-top: 47px;
    width: 100px;
    height: 100px;
  }
  .home-explanation-subtitle {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .product-body {
    height: 150px;
  }
  .explanation-title {
    height: 190px;
  }
}

.footer_list{
    list-style-type:none;
    margin: 0;
    padding: 0;
}

.footer_list li{
    margin-bottom: 5px;
}

.footer_list a{
    color: black;
    text-decoration: none;
}

.footer_list a:hover {
    color: #BB3634;
    text-decoration: none;
}
</style>
